<template>
  <v-container>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > e6-1" step="1">
        {{$t('create-agency.main-info')}}
      </v-stepper-step>

      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="1">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-folder-information-outline
                  </v-icon>
                  {{$t('create-agency.main')}}
                </v-chip>
                <v-row>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="agencyName" :label="$t('create-agency.agency-name')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider :rules="{required:true,length:3,alpha:true}" v-slot="{ errors }">
                      <v-text-field
                        v-model="prefix" :label="$t('create-agency.prefix')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="city" :label="$t('create-agency.city')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select
                        v-model="agencyType" :items="agencyTypes" :label="$t('create-agency.agency-type')" required clearable>
                      </v-select>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="address" :label="$t('create-agency.address')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <StepperContinueButton v-on:next-step="e6=++e6"
                                   :disabled="invalid"></StepperContinueButton>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step
        :complete="e6 > 2"
        step="2">
        {{$t('create-agency.contact-details')}}
      </v-stepper-step>

      <validation-observer
        ref="observer"
        v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="2">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="indigo" text-color="white">
                  <v-icon left>
                    mdi-card-account-phone-outline
                  </v-icon>
                  {{$t('create-agency.contact-details')}}
                </v-chip>
                <v-row>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider :rules="{required:true,email:true}" v-slot="{ errors }">
                      <v-text-field
                        v-model="email" :label="$t('create-agency.email')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider :rules="{required:true}" v-slot="{ errors }">
                      <v-text-field
                        v-model="mobile" :label="$t('create-agency.mobile')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider :rules="{required:true}" v-slot="{ errors }">
                      <v-text-field
                        v-model="phone" :label="$t('create-agency.phone')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="website" :label="$t('create-agency.website')" clearable persistent-hint hint="Use 'https://' format"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6"
                                       :disabled="invalid"></StepperContinueButton>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step
        :complete="e6 > 3"
        step="3">
        {{$t('create-agency.social-contacts')}}
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card outlined>
          <v-card-text>
            <v-chip label color="orange" text-color="white">
              <v-icon left>
                mdi-facebook
              </v-icon>
              {{$t('create-agency.social-networking')}}
            </v-chip>
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-model="skype" :label="$t('create-agency.skype')" clearable/>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="viber" :label="$t('create-agency.viber')" clearable/>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="linkedin" :label="$t('create-agency.linkedin')" clearable/>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="facebook" :label="$t('create-agency.facebook')" clearable/>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="instagram" :label="$t('create-agency.instagram')" clearable/>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="telegram" :label="$t('create-agency.telegram')" clearable/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col sm="6" md="6">
            <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
          </v-col>
          <v-col sm="6" md="6">
            <StepperContinueButton v-on:next-step="e6=++e6"></StepperContinueButton>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step step="4" :complete="e6 > 4">
        {{$t('create-agency.default-calculation-type')}}
      </v-stepper-step>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="4">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="blue" text-color="white">
                  <v-icon left>
                    mdi-page-previous-outline
                  </v-icon>
                  {{$t('create-agency.defaults')}}
                </v-chip>
                <v-row>
                  <v-col md="12">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete
                        v-model="calculationType" :items="calculationTypes" :label="$t('create-agency.calculation-type')" required
                        item-text="calculationTypeName" item-value="calculationTypeId">
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6"
                                       :disabled="invalid"></StepperContinueButton>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step step="5" :complete="e6 > 5">
        {{$t('create-agency.comments')}}
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-card outlined>
          <v-card-text>
            <v-chip label color="pink darken-2" text-color="white">
              <v-icon left>
                mdi-page-previous-outline
              </v-icon>
              {{$t('create-agency.comments')}}
            </v-chip>
            <v-row>
              <v-col sm="12" md="12">
                <v-textarea v-model="comments" class="purple-input" :label="$t('create-agency.comments')" clearable counter rows="2"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col sm="6" md="6">
            <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
          </v-col>
          <v-col sm="6" md="6">
            <StepperContinueButton v-on:next-step="e6=++e6"></StepperContinueButton>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step step="6" :complete="e6 > 6">
        {{$t('create-agency.logo')}}
      </v-stepper-step>
      <v-stepper-content step="6">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-chip label color="blue darken-2" text-color="white">
                  <v-icon left>
                    mdi-tray-arrow-up
                  </v-icon>
                  {{$t('create-agency.logo')}}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="logoFile" :label="$t('create-agency.logo-image')" prepend-icon="mdi-camera" show-size
                              :loading="loading" placeholder="Select a file"
                              accept="image/*" outlined persistent-hint
                              :hint="$t('create-agency.select-logo-hint')">
                  <template v-slot:selection="{ index, text }">
                    <v-chip color="blue darken-2" dark label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col sm="6" md="6">
            <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
          </v-col>
          <v-col sm="6" md="6">
            <v-btn :loading="loading" block color="primary" @click="saveAgency">
              {{$t('buttons.create-agency')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import StepperContinueButton from "../../components/buttons/StepperContinueButton";
  import StepperBackButton from "../../components/buttons/StepperBackButton";
  import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
  import {required} from 'vee-validate/dist/rules';
  import {length} from 'vee-validate/dist/rules';
  import {alpha} from 'vee-validate/dist/rules';
  import {email} from 'vee-validate/dist/rules';
  import {numeric} from 'vee-validate/dist/rules';
  import {useAgencies} from "../../stores/agenciesStore";
  import {mapState} from "pinia";

  const agencyStore = useAgencies()

  extend('required', {
    ...required,
    message: 'This field is required'
  });
  extend('length', {
    ...length,
    message: 'Length must be 3 characters'
  });
  extend('phone_length', {
    ...length,
    message: 'Invalid phone number'
  });
  extend('alpha', {
    ...alpha,
    message: 'Only alphabetic characters allowed'
  });
  extend('email', {
    ...email,
    message: 'Only valid e-mail address allowed'
  });
  extend('numeric', {
    ...numeric,
    message: 'Only numbers allowed'
  });

  let moment = require('moment');

  export default {
    name: "Create Agency",
    components: {ValidationProvider, ValidationObserver, StepperBackButton, StepperContinueButton},
    data() {
      return {
        message: '',
        calculationType: '',
        calculationTypes: [],
        loading: false,
        e6: 1,
        address: '',
        agencyName: '',
        agencyType: '',
        city: '',
        comments: '',
        email: '',
        mobile: '',
        phone: '',
        prefix: '',
        skype: '',
        telegram: '',
        viber: '',
        website: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        fileList: [],
        logoPath: '',
        files: '',
        fileName: '',
        agencyTypes: [this.$t('agency-types.legal-entity'), this.$t('agency-types.fop'), this.$t('agency-types.private-person')],
        logoFile: null
      }
    },
    methods: {
      uploadLogo() {
        const formData = new FormData();
        formData.append('file', this.logoFile);
        AXIOS.post(`/api/logoUpload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      saveAgency() {
        this.loading = true;
        let action = 'create'
        let objectType = 'Agency'
        let params = new URLSearchParams();
        this.logoFile ? this.uploadLogo() : null
        this.logoFile ? this.logoPath = this.logoFile.name : this.logoPath = 'no-logo.jpg'
        params.append('userId', this.currentUser.id);
        params.append('agencyName', this.agencyName);
        params.append('city', this.city);
        params.append('address', this.address);
        params.append('email', this.email);
        params.append('mobile', this.mobile);
        params.append('phone', this.phone);
        params.append('viber', this.viber);
        params.append('skype', this.skype);
        params.append('website', this.website);
        params.append('instagram', this.instagram);
        params.append('telegram', this.telegram);
        params.append('facebook', this.facebook);
        params.append('linkedin', this.linkedin);
        params.append('prefix', this.prefix);
        params.append('logoPath', this.logoPath);
        params.append('comments', this.comments);
        params.append('agencyType', this.agencyType);
        params.append('calculationType', this.calculationType);
        AXIOS.post(`/api/createAgency/`, params)
          .then(response => {
            agencyStore.addAgency(response.data);
            this.userNotification(response, action, objectType);
            this.loading = false;
            this.$router.push({path: '/agency/' + response.data.agencyId})
          })
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies'])
    },
    created() {
      this.getActiveCalculationTypes()
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
