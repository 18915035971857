<template>
  <v-btn color="primary" v-on:click="$emit('next-step')" block :disabled="disabled">
    {{$t('buttons.continue')}}
    <v-icon right dark>
      mdi-chevron-right
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    props: ['disabled'],
    name: "StepperContinueButton",
  }
</script>

<style scoped>

</style>
