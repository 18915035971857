<template>
  <v-btn color="danger" v-on:click="$emit('back-step')" block>
    {{$t('buttons.back')}}
    <v-icon right dark>
      mdi-chevron-left
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "StepperBackButton",
  }
</script>

<style scoped>

</style>
